import { Fragment } from "react";
import ParallaxSection from "../ParallaxSection.jsx";
import PortfolioSection from "../PortfolioSection.jsx";

import "./about.css";

export const About = ({ parallaxRef }) => {
  return (
    <Fragment>
      <ParallaxSection
        image={
          "https://i.pinimg.com/originals/e3/1b/41/e31b414441830071b13481159f6df0d7.jpg"
        }
        text="WELCOME TO MY PORTFOLIO"
        parallaxRef={parallaxRef}
        width={"100%"}
        height={"400px"}
      />
      <PortfolioSection
        title="ABOUT"
        renderContent={() => (
          <article className="content">
            <span className="content-first">
              <h4>PROFETIONALE BACKGROUND</h4>
              Senior software engineer with over 10 years of industry experience
              in software development, with a focus on delivering exceptional
              user experiences in the finance, e-commerce, healthcare,
              insurance, banking and more. I am proficient in advanced web
              technologies like HTML5, CSS3, TypeScript and JavaScript
              frameworks like React JS, Next.js. My strength is transforming
              complex designs into user-friendly and intuitive interfaces. My
              professional journey is aptitude by a commitment to detail,
              creative problem-solving, and technical excellence. I lead with a
              vision that encourages innovation and learning. I possess
              practical experience in enhancing performance, scalability,
              reliability, and security. Furthermore, I constantly learn and
              refine my work to keep me ahead in the industry. At present, I'm
              exploring into Generative AI and ML, and eager to bring
              cutting-edge solutions to enhance any web development best
              practices.
            </span>{" "}
            <span className="content-second">
              <h4>AREA OF EXPERTIES</h4>
              `Web Tools and Technologies: Html5, CSS3, JavaScript, React.js,
              Bootstraps, React Native, Vanila.js, Next.js, Angular.js.
              TypeScript, Node.js., Redux, Less, SCSS, Vite, Webpack.
              <ddiv className="divider" />
              Programming Languages:Python, Java, C++, Decent knowledge on PHP
              using Laravel, swift. Testing Libraries:JEST, Enzyme, React
              Testing Library, Cypress, Cucumber, Selenium.
              <div className="divider" />
              Version Control Tools:GitHub, GitLab Database:GraphQL, MySQL,
              MongoDB, and PostgreSQL.
              <div className="divider" />
              Other Tools:Jenkins, Segment, Amplitude, BrowserStack, Figma,
              Glass Box, Splunk, Perfecto, iOS Emulator / Simulator Online.
              <div className="divider" />
              Domain Knowledge: Banking, Finance, Health, Insurance, E-commerce.
              Industry Knowledge: SDLC, System Design, Test Driven Development
              (TDD), Behavioral Driven Development (BDD). `
            </span>
          </article>
        )}
      />
    </Fragment>
  );
};
