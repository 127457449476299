import { Fragment } from "react";
import ParallaxSection from "../ParallaxSection";
import PortfolioSection from "../PortfolioSection";
import { ProjectView } from "./ProjectView";
import myImage from "../../assets/images/me1.png";

export const Project = ({ parallaxRef }) => {
  return (
    <Fragment>
      <img
        src={myImage}
        alt=""
        style={{ opacity: ".7", filter: "grayscale(100%)" }}
      />
      <ParallaxSection
        image={
          "https://cdn.create.vista.com/api/media/small/123338794/stock-video-mars-rotating-on-solid-black-background-loopable?videoStaticPreview=true&token="
        }
        text="MY PROJECTS"
        parallaxRef={parallaxRef}
        width={"100%"}
      />
      <PortfolioSection
        head2style={{ visibility: "hidden" }}
        title="Projects"
        renderContent={() => <ProjectView />}
      />
    </Fragment>
  );
};
