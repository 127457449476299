import React from 'react';
import resume from '../../assets/files/resume.pdf';

import './header.css';

export const Header = ({ headers, onClick, activeTab }) => {
  return (
    <nav className='navbar'>
      {Array.isArray(headers) &&
        headers.map((header, index) => {
          return (
            <a
              key={index}
              className={`${activeTab === index ? 'active-tab' : ''}`}
              onClick={() => onClick(index)}
              href={`#${header}`}
            >
              {header}
            </a>
          );
        })}
      <a className='resume' href={`${resume}`} download>
        resume <span>⇩</span>
      </a>
    </nav>
  );
};
