import React from 'react';
import './loader.css';

export const Loader = () => {
  return (
    <div className='three-dots-container'>
      <div className='dot dot-1'></div>
      <div className='dot dot-2'></div>
      <div className='dot dot-3'></div>
    </div>
  );
};
