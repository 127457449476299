import React, { useState, useEffect } from "react";

export const ScrollProgressBar = () => {
  const [scrollProgress, setScrollProgress] = useState(0);

  const scrollListener = () => {
    const totalScroll = document.documentElement.scrollTop;
    const windowHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scroll = `${totalScroll / windowHeight}`;

    setScrollProgress(scroll);
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollListener);
    return () => window.removeEventListener("scroll", scrollListener);
  }, []);

  return (
    <div style={{ width: "100%", position: "fixed", top: 0 }}>
      <div
        style={{
          height: "5px",
          background: "goldenrod",
          width: `${scrollProgress * 100}%`,
        }}
      ></div>
    </div>
  );
};
