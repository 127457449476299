import React from "react";
import "../App.css";

function ParallaxSection({ image, text, parallaxRef, height, width }) {
  return (
    <div
      className="parallax-section"
      style={{
        backgroundImage: `url(${image})`,
        height: `${height}`,
        width: `${width}`,
      }}
    >
      <h1 ref={parallaxRef}>{text}</h1>
    </div>
  );
}

export default ParallaxSection;
