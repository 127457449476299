import React, { useEffect, useRef } from 'react';

import { constents, data } from '../header/Contents.js';
import { Header } from '../header/Tab.jsx';

import { Contact } from '../contact/Contact.jsx';
import { Project } from '../project/Project.jsx';
import { About } from '../about/About.js';

import './main.css';
import { Form } from '../form/Form.jsx';
import { ScrollProgressBar } from '../progressbar/progressbar.jsx';

export const Main = () => {
  const [active, setActive] = React.useState(0);
  const handleOnClick = (idx) => {
    setActive(idx);
    window.scrollTo({ behavior: 'smooth' });
  };
  const content = data.map((item) => item.content);

  const parallaxRef = useRef();

  function scrollFunction() {
    if (
      document.body.scrollTop > 400 ||
      document.documentElement.scrollTop > 400
    ) {
      parallaxRef.current.style.display = 'block';
    } else {
      parallaxRef.current.style.display = 'none';
    }
  }

  useEffect(() => {
    window.onscroll = function () {
      scrollFunction();
    };
  });

  return (
    <div className='main-container'>
      <ScrollProgressBar />
      <Header
        headers={constents}
        onClick={handleOnClick}
        activeTab={active}
        renderContent={() => (
          <div className='content-container'>
            <p>{content[active]}</p>
          </div>
        )}
      />
      <Form />
      <About parallaxRef={parallaxRef} />
      <Project parallaxRef={parallaxRef} />
      <Contact />
    </div>
  );
};
