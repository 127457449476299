import React, { useRef, useEffect } from "react";
import wells from "../../assets/images/fargo.png";
import allstate from "../../assets/images/allstate.png";
import zennify from "../../assets/images/zennify.png";
import avail from "../../assets/images/avail.png";
import { skills } from "./skillsContent";
// import { images } from "../../components/project/skillsContent";

import "./project.css";

export const ProjectView = () => {
  // const imageRef = useRef(null);
  const skillsRef = useRef(null);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    skillsRef.current.style.transform = `translateY(${-scrollY * 0.2}px)`;
    // imageRef.current.style.transform = `translateY(${-scrollY * 0.4}px)`;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div className="project-view-container">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "50px",
        }}
      >
        <h2>ENTERPRISE EXPERIENCE</h2>
        <img width={"100px"} src={`${wells}`} alt="" />
        <img width={"100px"} src={`${allstate}`} alt="" />
        <img width={"150px"} src={`${avail}`} alt="" />
        <img width={"200px"} src={`${zennify}`} alt="" />{" "}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h2>SKILLS</h2>
        <div className="skills-container">
          {skills.map((skill, index) => (
            <div className="skills" key={index} ref={skillsRef}>
              {skill}
            </div>
          ))}
        </div>
      </div>
      {/* <div ref={imageRef} className="image-container">
        {images.map((img, index) => (
          <div key={index}>
            <img
              src={img}
              alt=""
              style={{ width: "70px", height: "70px", borderRadius: "100%" }}
            />
          </div>
        ))}
      </div> */}
    </div>
  );
};
