import React from "react";
import "../App.css";

function PortfolioSection({ title, renderContent, head2style }) {
  return (
    <div className="portfolio-section" id={title.toLowerCase()}>
      <h2 style={head2style}>{title}</h2>
      <div>{renderContent && renderContent()}</div>
    </div>
  );
}

export default PortfolioSection;
