import React, { Fragment, useState } from 'react';

import { Loader } from '../loader/Loader';
import OpenAI from 'openai';
import './form.css';

export const Form = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [htmlElement, setHtmlElement] = useState({
    tagName: '',
    description: '',
  });

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY, // This is also the default, can be omitted
    dangerouslyAllowBrowser: true,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    await openai.chat.completions
      .create({
        model: 'gpt-4-1106-preview',
        messages: [{ role: 'user', content: searchTerm }],
      })
      .then((res) => {
        setHtmlElement({
          tagName: searchTerm,
          description: res.choices[0].message.content,
        });
      })
      .catch((error) => {
        console.error('An error occurred while fetching the data', error);
      });
    setIsLoading(false);
    setSearchTerm('');
  };

  return (
    <Fragment>
      <form className='form-container' onSubmit={handleSubmit}>
        <input
          type='text'
          value={searchTerm}
          onChange={handleChange}
          placeholder='Ask question here...'
        />
        <button disabled={!searchTerm} type='submit'>
          Search
        </button>
      </form>
      {isLoading ? (
        <Loader />
      ) : (
        htmlElement && (
          <div className='text-container'>
            <h2 style={{ color: '#48a4a5' }}>{htmlElement.tagName}</h2>
            <p className='description'>{htmlElement.description}</p>
          </div>
        )
      )}
    </Fragment>
  );
};
