import { About } from '../about/About.js';
import { Contact } from '../contact/Contact.jsx';
import { Project } from '../project/Project.jsx';

export const constents = [
  'portfolio@shekhar chaudhary',
  'about',
  'projects',
  'contact',
];
export const data = [
  {
    content: <About />,
  },
  { content: <Project /> },
  { content: <Contact /> },
];
