import react from "../../assets/images/skillsImage/react.png";
import html from "../../assets/images/skillsImage/html.jpeg";
import css3 from "../../assets/images/skillsImage/css3.jpeg";
import javascript from "../../assets/images/skillsImage/javascript.webp";
import node from "../../assets/images/skillsImage/node.png";
import redux from "../../assets/images/skillsImage/redux.png";

export const skills = [
  "React",
  "Redux",
  "Typescript",
  "Html5",
  "CSS3",
  "Enzyme",
  "Testing Library",
  "Python",
  "Java",
  "C++",
  "Node.js",
  "Next.js",
  "GraphQl",
  "Jenkins",
  "Webpack",
  "Babel",
  "Github",
  "Postman",
  "CI/CD",
  "Saga",
  "SQL",
  "Web API",
  "Slunk",
  "React",
  "Redux",
  "Typescript",
  "Html5",
  "CSS3",
  "Enzyme",
  "Testing Library",
  "Python",
  "Java",
  "C++",
  "Node.js",
  "Next.js",
];

export const images = [html, css3, node, redux, react, javascript];
