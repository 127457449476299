import { Fragment, useRef, useEffect } from 'react';
import ParallaxSection from '../ParallaxSection';
import PortfolioSection from '../PortfolioSection';
import { Button } from '../button/Button';

import './contact.css';

export const Contact = () => {
  const buttonRef = useRef();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  function scrollFunction() {
    if (
      document.body.scrollTop > 400 ||
      document.documentElement.scrollTop > 400
    ) {
      buttonRef.current.style.display = 'block';
      buttonRef.current.style.opacity = '1';
      buttonRef.current.style.visibility = 'visible';
    } else {
      buttonRef.current.style.display = 'none';
      buttonRef.current.style.opacity = '0';
      buttonRef.current.style.visibility = 'hidden';
    }
  }

  useEffect(() => {
    window.onscroll = function () {
      scrollFunction();
    };
  });
  return (
    <Fragment>
      <ParallaxSection
        image={'https://wallpapercave.com/wp/wp6657119.jpg'}
        text='CONTACT'
        width={'100%'}
        height={'750px'}
      />
      <div className='calender'>
        <p>ADD AN EVENT TO MY CALENDAR :</p>
        <a
          href='https://calendly.com/webdevshekhar'
          target='_blank'
          rel='noreferrer'
          style={{ color: 'white', textDecoration: 'none', fontSize: '12px' }}
        >
          {''} CLICK HERE
        </a>
      </div>
      <PortfolioSection
        head2style={{ visibility: 'hidden' }}
        title='contact'
        renderContent={() => (
          <p className='fotter-content'>
            <span>Email: webdevshekhar@gmail.com</span> |
            <span> Contact: 5104177347 </span> |
            <span className='linkedin'>
              LinkedIn:
              <a
                href='https://www.linkedin.com/in/shekhar-chaudhary-b5a759148'
                target='_blank'
                rel='noreferrer'
                style={{
                  color: 'white',
                  textDecoration: 'none',
                  fontSize: '12px',
                }}
              >
                {''} CLICK HERE
              </a>
            </span>
            <span>
              <Button
                handleClick={scrollToTop}
                text={'RETURN TO TOP'}
                buttonRef={buttonRef}
              />
            </span>
          </p>
        )}
      />
    </Fragment>
  );
};
